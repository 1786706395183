// !!! If testing this code on any of the staging servers,
// your ad-blocker or equivalent may prevent any messages
// reaching Sentry.
import * as Sentry from '@sentry/browser';
import {
  contextLinesIntegration,
  extraErrorDataIntegration,
  httpClientIntegration,
} from '@sentry/integrations';
import supportedBrowsersRegex from './generated/supportedBrowsersRegex';

// Ultimately generated via browserlistrc in SWC.
// Additionally we use the Sentry settings here https://morphmarket.sentry.io/settings/projects/morphmarket/filters/data-filters/
const isSupportedBrowser = () => {
  return !!supportedBrowsersRegex.test(navigator.userAgent);
};

// Google Translate (and possibly other extentions) don't play well with React
// and can trigger errors. We won't to be aware of this dimension.
const isPageBeingTranslated = () => {
  const termsAndConditionsLink = document.getElementById('terms-and-conditions-link');
  if (!termsAndConditionsLink) {
    // eslint-disable-next-line no-console
    console.warn(
      'Could not find terms-and-conditions-link. This will break some Sentry functionality.'
    );
    return false;
  }
  return termsAndConditionsLink.innerText != 'Terms & Conditions';
};

const isNewRelicError = (event) => {
  // Incomplete info - abort.
  if (!event || !event.breadcrumbs) {
    return false;
  }

  // Refer to docs for the exact shape of each breadcrumb type https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
  const consoleBreadcrumbs = event.breadcrumbs.filter((event) => {
    return event.category == 'console';
  });

  // We get 1000s of ChunkLoadError due to NewRelic and don't want to report them.
  const isChunkLoadError = consoleBreadcrumbs.some((event) => {
    return event.message.match(/ChunkLoadError/) && event.message.match(/newrelic/);
  });

  if (isChunkLoadError) {
    return true;
  }

  return false;
};

const isTranslateApiError = (event) => {
  // Incomplete info - abort.
  if (!event || !event.breadcrumbs) {
    return false;
  }

  // There are two types of error and we test for each here.
  //
  // 1. React failing due to changes in the DOM by Google Translate API
  if (isPageBeingTranslated() && event.exception && event.exception.values) {
    const reactErrors = event.exception.values.filter((value) => {
      return (
        value.type == 'NotFoundError' &&
        (value.value.match(/The node to be removed is not a child of this node/) ||
          value.value.match(/removeChild\(\[native code/) ||
          value.value.match(/The object can not be found here/))
      );
    });

    const jQueryErrors = event.exception.values.filter(
      (value) => value.type == 'UnhandledRejection' && value.value.match(/MethodName:simulateEvent/)
    );

    if (reactErrors.length > 0 || jQueryErrors.length > 0) {
      return true;
    }
  }

  // 2. Google Translate API failing

  const translateRegex = /https:\/\/translate.googleapis.com\//;
  const undefinedRegex = /undefined is not an object \(evaluating '[a-zA-Z]\.[a-zA-Z]'\)/;

  // Refer to docs for the exact shape of each breadcrumb type https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
  const translateBreadcrumbs = event.breadcrumbs.filter((event) => {
    return event.category == 'xhr' && event.data.url.match(translateRegex);
  });

  const undefinedBreadcrumbs = event.breadcrumbs.filter((event) => {
    return event.category == 'error' && event.message.match(undefinedRegex);
  });

  // We are getting a lot of errors from the translate API (browser extension) and don't want to report them.
  if (translateBreadcrumbs.length > 0 || undefinedBreadcrumbs.length > 0) {
    return true;
  }

  return false;
};

// These will be used to match both error type and message.
const ignoreErrors = [
  // This error seems to be due to an upstream iOS issue out of our control
  '_AutofillCallbackHandler',
  // This error is due to New Relic internals and not our problem
  'harvest.stopTimer',
  /nrWrapper/, // Another newrelic error
  // Due to Fair Ad Block chrome extension
  /BetterJsPop/,
  // Ignore issues due to Next DNS
  /checkoutSettingKeys\.devToolsEnabled/,
  // (only 98% sure) This seems unrelated or our codebase
  /ceCurrentVideo\.currentTime/,
  // (only 98% sure). These seem related to a few old browsers and the (non critical) service worker,
  /workbox-window/,
  /ServiceWorkerContainer/,
  // This is due to the non-critical service worker.
  /Failed to register a ServiceWorker/,
  /initServiceWorkerRegistration/,
  // This is due to a transient bug in Android WebView that we can do nothing about. https://github.com/react-native-webview/react-native-webview/issues/2680.
  // Note that when this happens, it is likely that app users will have a degraded experience and partial failures.
  /window\.ReactNativeWebView\.postMessage is not a function/,
  /t\.postMessage is not a function/,
  /e\.contentWindow\.postMessage/,
  // This error has no information and -- even if it indicates a real problem - is not actionable. See https://github.com/getsentry/sentry-javascript/issues/2546
  // and 4953.
  /Non-Error promise rejection captured with keys: currentTarget/,
  /Non-Error promise rejection captured.+ MethodName:update/,
  // The next 3 errors happens if the user hits the refresh button mid-request. This is incredibly common
  // and not worth reporting. The one potential downside here is that we miss some CORS errors, which
  // can sometimes throw the same exceptions. But considering we get 10,000s of these errors due to the refresh
  // button, the signal-to-noise ratio makes this unusable as a way of detecting CORS errors.
  // See https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
  'TypeError: Failed to fetch',
  'TypeError: NetworkError when attempting to fetch resource.',
  'TypeError: cancelled',
  'TypeError: Load failed',
  'TypeError: zrušeno',
  /AbortError/, // Happens if the user aborts a request.
  /NetworkError: Load failed/,
  /Network Error'/,
  /The network connection was lost./,
  // We also need to ignore the same errors above in Chinese characters
  /已取消/,
  // When Heroku has downtime, either Heroku or CloudFlare will end up returning
  // a generic HTML page even when the browser requested JSON. This is not actionable.
  /is not valid JSON/,
  /after array element in JSON at position/, // due to getting HTML instead of JSON too, probably
  /JSON.parse: unexpected character at line 1/,
  /Unexpected identifier 'pos'/, // due to getting HTML instead of JSON too, probably
  // This is when a promise is rejected with no error. This is not actionable therefore
  // we might as well ignore it and not waste quota.
  /Non-Error promise rejection captured with value: undefined/,
  // The service worker script will randomly fail but it's inessential.
  /sw.js load failed/,
  // Unactionable exception
  /Event `CustomEvent`/,
  // Errors from the browser's ResizeObserver that seem harmless.
  /ResizeObserver loop completed with undelivered notifications./,
  // Workbox error that seems to be harmless.
  /The operation is insecure./,
  // Spam from a third party
  /populateWithMappings/,
  // MUI SwipeableDrawer internal error
  /evaluating 'ee.current.contains'/,
  /Cannot read properties of null (reading 'contains')/,
  // Service worker rubbish that is not important
  /Service Worker script execution timed out/,
  /wrsParams.serviceWorkers/,
  /ServiceWorker script at/,
  // Legacy code trash
  /d("#advanced-search-bar").collapse is not a function/,
  // Legacy code trash
  /d('[data-toggle="popover"]').popover is not a function/,
  /d(...).popover is not a function/,
  /d(...).collapse is not a function/,
  // Google maps trash
  /Could not load "places_impl"/,
  /Cannot read properties of undefined (reading 'isBroken')/,
  /Object captured as promise rejection with keys: sentMessage, stack/,
  /Can't find variable: zaloJSV2/,
  // Unimportant load order issues
  /window.MorphMarket.toggleSpinner/,
  /GetHTMLElementsAtPoint/,
  /ucbrowser_script/,
  // Seems related to perf issues
  /Loading CSS chunk/,
  /scanForForms/, // some Java Bridge error
  // Annoying gtag error
  /b.container\[a\]/,
  // Annoying gtag error
  /getRestrictions is not a function/,
  // Another gtag error
  /moveSelectionRangeOutsideView/,
  // Some bootstrap error in the legacy code
  /tooltip is not a function/,
  /collapse is not a function/,
  /popover is not a function/,
  // Unsure of origin, but doesn't appear in our code
  /registerMyClickListener/,
  // Some legacy library that has load order issues rarely
  /initGeocoder/,
  // Unactionable error
  /Object captured as promise rejection with keys: \[object has no keys\]/,
  // Windows only, only 6 users over a year, no bug reports, no stack trace.
  /'label\[for=:r0:\]' is not a valid selector/,
  // Errors from some third-party translation extension -- not our code
  /NaverTranslator.setTranslateResponse/,
  // More Google translate errors
  /translate\.goog/,
  // Square Network requests internally - not our code and cannot fix
  /Network error.*square/,
  // Some blockchain extension error
  /solana/,
  /ethereum/,
  // LocalStorage issues in strange execution environments
  /The document is sandboxed and lacks the 'allow-same-origin' flag/,
  /Blocked a frame with origin "null" from accessing a cross-origin frame/,
  // Long-standing issue with DropZone that doesn't seem to cause any problems
  /Cannot destructure property 'files' of 'this.hiddenFileInput'/,
];

const isGenerallyIgnoredError = (event) => {
  if (event.exception && event.exception.values) {
    const errors = event.exception.values.filter((value) => {
      return ignoreErrors.some((ignoreError) => {
        if (typeof ignoreError === 'string') {
          return value.value.includes(ignoreError) || value.type.includes(ignoreError);
        } else {
          return value.value.match(ignoreError) || value.type.match(ignoreError);
        }
      });
    });

    if (errors.length > 0) {
      return true;
    }
  }
};

if (window.SENTRY_FRONTEND_ENABLED) {
  if (
    window.SENTRY_FRONTEND_ONLY_USERNAMES.length === 0 ||
    window.SENTRY_FRONTEND_ONLY_USERNAMES.indexOf(window.USERNAME) !== -1
  ) {
    Sentry.init({
      dsn: window.SENTRY_DSN,
      // Uncomment this line in order to test Sentry in localhost. Otherwise
      // the errors will not get sent to Sentry.com
      // debug: true,
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true,
        }),
        // This integration adds source code from inline JavaScript of the current page's HTML (e.g. JS in <script> tags) to stack traces of captured errors.
        contextLinesIntegration(),
        // This integration extracts all non-native attributes from the error object and attaches them to the event as extra data. If the error object has a .toJSON() method, the ExtraErrorData integration will run it to extract additional information.
        extraErrorDataIntegration(),
        // This integration captures errors on failed requests from Fetch and XHR and attaches request and response information.
        httpClientIntegration(),
      ],
      // NB: This is only FE errors. You need to ignore BE errors in the BE config code.
      ignoreErrors: ignoreErrors,
      // This sets the sample rate for non-error related replays.
      replaysSessionSampleRate: 0.0,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 0.1,
      // release version must be set when deploying
      release: window.SENTRY_RELEASE_VERSION,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // This incurs a financial cost so we skip it.
      tracesSampleRate: 0,
      // Controls whether we send an individual event to Sentry. Acts independently of
      // ignoreErrors. If an event is returned, we send it to Sentry. If null is returned,
      // we drop it.
      beforeSend(event) {
        if (window.IS_DEBUG) {
          // eslint-disable-next-line no-console
          console.debug('beforeSend Sentry', event);
        }

        if (isGenerallyIgnoredError(event)) {
          // drop unwanted events
          // eslint-disable-next-line no-console
          console.warn('Dropping generally ignored error from Sentry', event);
          return null;
        }

        if (isNewRelicError(event)) {
          // drop unwanted events
          // eslint-disable-next-line no-console
          console.warn('Dropping NewRelic error from Sentry', event);
          return null;
        }

        if (isTranslateApiError(event)) {
          // drop unwanted events
          // eslint-disable-next-line no-console
          console.warn('Dropping Google Translate API error from Sentry', event);
          return null;
        }

        // Check if it is an exception, and if so, show the report dialog
        if (event.exception && event.event_id) {
          // TODO: Temporarily disabling due to the fact that this triggers for minor errors that do no crash the page.
          // and we still have too many of them. Perhaps it would be wiser to limit to the React app error boundary
          // somehow.
          //
          // Sentry.showReportDialog({ eventId: event.event_id });
        }

        if (!isSupportedBrowser()) {
          // drop events from unsupported browsers
          // eslint-disable-next-line no-console
          console.warn('Dropping unsupported browser error from Sentry', event);
          return null;
        }

        event.tags = event.tags || {};
        // Setting here because translation happens long after the page load and any
        // initial value with Sentry.setTags() would likely be wrong.
        event.tags['isPageTranslated'] = isPageBeingTranslated() ? 'true' : 'false';

        // Send wanted events
        return event;
      },
    });

    Sentry.setUser({ id: window.USERNAME });

    // This allows us to filter in Sentry for `app:true`
    // and find errors that only occur in the app.
    if (window.IS_APP) {
      Sentry.setTag('app', 'true');
    } else {
      Sentry.setTag('app', 'false');
    }
    Sentry.setTag('isSupportedBrowser', isSupportedBrowser() ? 'true' : 'false');
    Sentry.setTag('country', window.USER_COUNTRY);

    window.Sentry = Sentry;
  }
}
